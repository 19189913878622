import { makeStyles } from "@material-ui/core";
import { AudioStatus } from "./utils/TrackUtils";

const style = makeStyles(theme => ({
    container: {
        display: "flex",
        width: 20,
        height: 20,
        border: "solid 1px",
        borderRadius: "50%",
        background: props =>
            props.status == AudioStatus.ON
                ? "red"
                : props.status === AudioStatus.MUTED
                ? "orange"
                : "grey"
    }
}));

function ParticipantStatus({ status }) {
    const classes = style({ status: status });
    return <span className={classes.container}></span>;
}

export default ParticipantStatus;
