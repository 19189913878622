import { connect } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import Notice from "../partials/content/Notice";
import { UserRole } from "./utils/UserRole";

const useStyles = makeStyles(theme => ({
    headerContent: {
        margin: 0
    },
    headerShadowNone: {
        boxShadow: "none !important"
    },
    title: {
        display: "inline-block"
    },
    description: {
        display: "inline-block",
        margin: theme.spacing(1)
    }
}));

function ExtraHeader(props) {
    console.log("🚀 ~ ExtraHeader ~ props:", props);
    const { event, isStartMeeting } = props;
    const classes = useStyles();
    return (
        <Notice
            className={`notice__paragraph ${classes.headerContent} ${isStartMeeting &&
                classes.headerShadowNone}`}
            id="content_header"
        >
            <Typography variant="h6" className={classes.title}>
                {event ? event.title : ""}
            </Typography>
            <Typography className={classes.description} variant="subtitle1">
                {event ? event.detail : ""}
            </Typography>
        </Notice>
    );
}

const mapStateToProps = state => {
    const props = { isStartMeeting: state.event.isStartMeeting, event: state.event.event };

    return {
        ...props,
        event:
            state.events.event ||
            state.adminReducer.event ||
            state.customerReducer.event ||
            state.interpreterReducer.event ||
            state.emitterReducer.event
    };
};

export default connect(mapStateToProps)(ExtraHeader);
