import React from "react";
import { makeStyles, withStyles, LinearProgress } from "@material-ui/core";

// function _getInterpreters(participants) {
//     return participants ? participants.filter(p => parseInt(p.getProperty('role')) === UserRole.INTERPRETER) : []
// }

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: 100,
        marginLeft: "auto",
        marginRight: "auto",
        transform: props => (props.direction === "horizontal" ? "" : "rotate(270deg)")
    }
}));

const BorderLinearProgress = withStyles(theme => ({
    root: {
        margin: 5
    },
    colorPrimary: {
        backgroundColor: "#9dd6ff"
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#1a90ff"
    }
}))(LinearProgress);

function AudioDetector(props) {
    const { pId, audioLevels } = props;
    const classes = useStyles(props);
    const audioLevel =
        pId && audioLevels && audioLevels.length > 0 ? audioLevels.find(a => a.userId === pId) : 0;

    return (
        <div className={classes.wrapper}>
            <BorderLinearProgress
                className={classes.linearProgress}
                variant="determinate"
                value={
                    audioLevel
                        ? audioLevel.audioLevel > 2 / 3
                            ? 100
                            : audioLevel.audioLevel * 150
                        : 0
                }
            />
        </div>
    );
}

export default AudioDetector;
