import { makeStyles, colors } from "@material-ui/core";
const { common } = colors;

export const SUBTITLE_HEIGHT = 100;
export const LEFTPANEL_WIDTH = 300;
export const meeting = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    eventPlace: {
        marginTop: props => (props.isStartMeeting ? 0 : 20),
        marginLeft: 0,
        marginRight: props => (props.isStartMeeting ? 0 : -10)
    },
    videoPlace: {
        flex: "100%",
        // maxWidth: `calc(100% - 380px - ${LEFTPANEL_WIDTH}px)`,
        [theme.breakpoints.down("sm")]: {
            flex: "initial",
            maxWidth: "initial"
        }
    },
    control: {
        // width: 340,
        // maxWidth: 340,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            maxWidth: "100%"
        },
        marginLeft: "auto"
    },
    fullWidthControl: {
        position: "absolute",
        right: 20
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    controlRow: {
        padding: theme.spacing(1),
        flexDirection: "row",
        flexWrap: "nowrap"
    },
    table: {
        // minWidth: 350
    },
    tableWrapper: {
        overflowX: "auto"
    },
    button: {
        margin: theme.spacing(1)
    },
    flag: {
        width: 32,
        height: 24,
        marginRight: theme.spacing(1),
        borderRadius: 4
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    formControl: {
        width: "100%"
    },
    rightPanel: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    langControl: {
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium
    },
    volumeControl: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        marginBottom: theme.spacing(1)
    },
    volume: {
        margin: theme.spacing(1)
    },
    channelUsersContainer: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1)
    },
    leftPanelContainer: {
        // maxWidth: LEFTPANEL_WIDTH
    },
    panelStatusTableTD: {
        border: "solid 1px black",
        textAlign: "center"
    },
    statusWrapper: {
        display: "flex"
    },
    label: {
        textTransform: "uppercase"
    },
    labelName: {
        textTransform: "capitalize"
    },
    langActiveBtn: {
        color: "black",
        borderColor: "rgba(251, 0, 0, 0.5)",
        "&:hover": {
            borderColor: "rgba(251, 0, 0, 1)",
            backgroundColor: "rgba(251, 0, 0, 0.04)"
        }
    }
}));

export const meetingVideo = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 350
    },
    tableWrapper: {
        overflowX: "auto"
    },
    avatar: {
        position: "absolute",
        top: "5%",
        left: "5%",
        zIndex: 1,
        "& img": {
            width: 80
        },
        [theme.breakpoints.down("sm")]: {
            "& img": {
                width: 50
            }
        }
    },
    meetingStatus: {
        position: "absolute",
        top: "2%",
        left: "50%",
        transform: "translate(-50%, 0%)",
        zIndex: 1,
        color: "red",
        border: "solid 1px",
        padding: theme.spacing(1)
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content"
    },
    formControl: {
        // marginTop: theme.spacing(2),
        minWidth: 120
    },
    // formControlLabel: {
    //     marginTop: theme.spacing(1),
    // },
    button: {
        margin: theme.spacing(1)
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    wrapper: {
        // display: none
    },
    loadingSection: {
        position: "absolute",
        background: "#525050",
        width: "100%",
        height: "100%",
        top: 0
    },
    loadingContent: {
        position: "absolute",
        left: 0,
        top: "50%",
        textAlign: "center",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white"
    },
    localPlace: {
        position: "relative",
        // height: '100%',
        paddingTop: "calc(56.25%)",
        [theme.breakpoints.down("sm")]: {
            paddingTop: "calc(67.25%)"
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: "calc(67.25%)"
        },
        // marginBottom: 56,
        overflowY: "hidden"
    },
    fullLayout: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    mainWrapper: {
        position: "relative",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
    },
    mainPlaceWrapper: {
        position: "relative",
        width: "100%",
        height: props =>
            props.isSubTitle
                ? `calc(100% - 56px - 4px - ${SUBTITLE_HEIGHT}px)`
                : "calc(100% - 56px - 4px)",
        top: 0,
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "stretch",
        justifyItems: "baseline",
        background: "#525050"
    },
    mainPanel: {
        display: "flex",
        flex: "100%",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "100% !important"
        },
        maxWidth: props => (props.showInfoPanel ? "calc(100% - 200px)" : "100%"),
        position: "relative",
        alignItems: "flex-end"
    },
    sidePanel: {
        maxWidth: 200,
        background: "white",
        height: "100%",
        width: props => (props.showInfoPanel ? "100%" : ""),
        [theme.breakpoints.down("sm")]: {
            width: "initial !important"
        }
    },
    videoMuteArrow: {
        position: "absolute",
        right: 0,
        background: "white",
        bottom: "6px",
        borderRadius: "50%",
        "&:hover": {
            background: "#d3cece"
        }
    },
    bigVideoWrapper: {
        position: "relative",
        width: "100%",
        height: "100%",
        display: props => (props.isMosaic ? "none" : "flex"),
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    bigVideoFullWidth: {
        width: "100% !important"
    },
    bigVideo: {
        width: "100%",
        height: props => (props.isSubTitle ? `calc(100%)` : "calc(100%)"),
        position: "relative",
        top: 0,
        left: 0
    },
    sidePlace: {
        position: "relative",
        top: 0,
        right: 0,
        maxWidth: props => (props.isMosaic ? "100%" : "170px"),
        height: "calc(100%)",
        overflowY: "visible",
        overflowX: props => (props.isSidePlaceShow ? "visible" : "hidden"),
        "& .sideWrapper": {
            position: "relative",
            width: props => (props.isMosaic ? "100%" : "100%"),
            maxWidth: props => (props.isMosaic ? "100%" : "166px"),
            right: 0,
            transition: "right .3s ease-in",
            minWidth: 73,
            height: "100%",
            overflow: "auto"
        },
        "& .ps": {
            overflow: "unset !important"
        }
    },
    participantContainer: {
        display: "block",
        position: "relative",
        borderRadius: 4,
        cursor: "pointer",
        background: common.black,
        margin: 2,
        marginTop: 6
    },
    participantContainerFocused: {
        border: "2px solid",
        borderColor: "#165ecc",
        boxShadow: "inset 0 0 3px #165ecc, 0 0 3px #165ecc"
    },
    video: {
        width: "100%",
        borderRadius: 4,
        height: "100%",
        top: 0,
        left: 0,
        position: props => (props.isMosaic ? "absolute" : ""),
        marginTop: props => (props.isMosaic ? 0 : "-75%")
    },
    remotePlace: {
        position: "relative",
        padding: theme.spacing(1),
        width: "100%",
        height: "100%",
        display: props => (props.isMosaic ? "flex" : ""),
        flexWrap: "wrap",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto"
    },
    chatContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "calc(100%)",
        zIndex: 1,
        [theme.breakpoints.down("xs")]: {
            height: "calc(100%)"
        }
    },
    badge: {
        margin: theme.spacing(1)
    },
    bottomIcons: {
        position: "absolute",
        right: 0,
        bottom: 0
    },
    mosaicPlace: {},
    mosaicOverlay: {
        background: "#525050",
        width: "100%",
        // zIndex: 500,
        height: "100%",
        display: "block",
        position: "absolute",
        opacity: 1,
        top: 0
    },
    mosaicRemoteContainer: {
        // top: "calc(50% - 56px)",
        // transform: "translateY(-50%)",
        top: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down("xs")]: {
            bottom: 0
        }
    },
    subTitleWrapper: {
        height: SUBTITLE_HEIGHT,
        width: "100%",
        position: "relative",
        bottom: 0,
        padding: "5px 5px 0px 5px"
    },
    subTitle: {
        background: "#1c1c1c",
        height: "100%",
        border: "solid 1px #1c1c1c",
        borderRadius: 7
    },
    subTitleIframe: {
        width: "100%",
        height: "100%",
        border: "solid 1px #1c1c1c",
        borderRadius: 7
    },
    videoContainer: {
        display: "flex",
        alignItems: "center",
        paddingTop: props => (props.isMosaic ? "56.25%" : "75%")
    },
    noVideo: {
        display: "none"
    },
    mosaicVideo: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        borderRadius: "4px",
        border: "solid 2px"
    },
    hand: {
        position: "absolute",
        right: 0,
        top: 0,
        color: "white",
        background: "blue",
        border: "1px solid",
        borderRadius: "50%",
        padding: 2,
        display: "flex",
        margin: "5px",
        "& i": {
            fontSize: "0.8rem"
        }
    },
    hiddenParticipant: {
        display: "none"
    },
    toolBoxWrapper: {
        display: "flex",
        position: "relative"
    },
    extraVideo: {
        height: 500,
        [theme.breakpoints.down("sm")]: {
            height: 200
        }
    },
    lobbyTitle: {
        margin: theme.spacing(4, 2, 0)
    }
}));
