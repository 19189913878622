import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import * as eventsStore from "../../../../store/ducks/events.duck";

import Meeting from "./Meeting/Meeting";

function Event(props) {
    const { event, getEvent } = props;
    const event_id = props.match.params.id;
    const [currentEvent, setCurrentEvent] = useState(null);
    useEffect(() => {
        if (event_id) {
            getEvent(event_id);
            window.addEventListener("keydown", handleKeyDown);
        }
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [event_id, getEvent]);

    useEffect(() => {
        if (event) {
            setCurrentEvent(event);
        }
    }, [event]);

    function handleKeyDown(event) {
        if (event.keyCode === 32) {
            const activeElement = document.activeElement;
            if (activeElement.id !== "chatInput" && activeElement.id !== "warningInput") {
                event.preventDefault();
            }
        }
    }

    return <>{!currentEvent ? "" : <Meeting event={currentEvent} />}</>;
}

const mapStateToProps = state => {
    return {
        event: state.events.event
    };
};

const mapDispatchToProps = dispatch => ({
    getEvent: id => dispatch(eventsStore.actions.getEvent(id))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Event));
