import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import ExtraHeader from "./ExtraHeader";

const useStyles = makeStyles(theme => ({
    headerContent: {
        margin: 0
    },
    headerShadowNone: {
        boxShadow: "none !important"
    },
    title: {
        display: "inline-block"
    },
    description: {
        display: "inline-block",
        margin: theme.spacing(1)
    }
}));

function EventLayout(props) {
    const { event, screenMode, isStartMeeting, fullElement, hidden, children } = props;
    const classes = useStyles();

    useEffect(() => {
        if (!isStartMeeting && event) {
            // const appendContent = document.getElementById("content_header");
            const videoContent = document.getElementById("eventPlace");
            if (videoContent) {
                // videoContent.parentNode.insertBefore(appendContent, videoContent);
                _exitFullScreenElement();
                const eventContainer = document.getElementById("eventContainer");
                eventContainer.parentNode.style.width = null;
            }
        }
    }, [isStartMeeting, event]);

    useEffect(() => {
        if (event) {
            function _changeSize(value) {
                try {
                    if (value === 2) {
                        const elem = document.getElementById(fullElement ?? "localPlace");
                        if (typeof elem.requestFullscreen === "function") {
                            elem.requestFullscreen().catch(err => {});
                        } else if (typeof elem.msRequestFullscreen === "function") {
                            elem.msRequestFullscreen();
                        } else if (typeof elem.mozRequestFullScreen === "function") {
                            elem.mozRequestFullScreen();
                        } else if (typeof elem.webkitRequestFullscreen === "function") {
                            elem.webkitRequestFullscreen();
                        }
                    } else if (value === 1) {
                        _exitFullScreenElement();
                        const eventContainer = document.getElementById("eventContainer");
                        if (eventContainer) {
                            eventContainer.parentNode.style.width = "100%";
                        }
                    } else {
                        _exitFullScreenElement();
                        const eventContainer = document.getElementById("eventContainer");
                        if (eventContainer) {
                            eventContainer.parentNode.style.width = null;
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            }

            _changeSize(screenMode);
        }
    }, [screenMode, fullElement, event]);

    function _exitFullScreenElement() {
        try {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else if (document.msFullscreenElement) {
                document.msExitFullscreen();
            } else if (document.mozFullscreenElement) {
                document.mozCancelFullScreen();
            } else if (document.webkitFullscreenElement) {
                document.webkitCancelFullscreen();
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {event ? (
                <div id="eventContainer" style={{ flexGrow: 1 }}>
                    {!hidden && <ExtraHeader event={event} />}
                    {children}
                </div>
            ) : (
                ""
            )}
        </>
    );
}

export default EventLayout;
