import { v4 as uuidV4 } from "uuid";
import fixWebmDuration from "webm-duration-fix";
import { CHANNEL_TYPE, MEDIA_TYPE } from "./utils/constants";
import { inIframe } from "./utils/iframeUtils";
import * as eventStore from "../store/ducks/event.duck";
import { getLocalTrack } from "./utils/TrackUtils";
import { ORIGINAL_ROOMNAME } from "./JitsiMeeting";
import { UserRole } from "./utils/UserRole";

const VIDEO_BIT_RATE = 2500000; // 2.5Mbps in bits
const MAX_SIZE = 1073741824; // 1GB in bytes

class LocalRecordingManager {
    constructor() {
        this.recordingData = [];
        this.recorder = undefined;
        this.stream = undefined;
        this.audioContext = undefined;
        this.oscillator = undefined;
        this.audioDestination = undefined;
        this.roomName = "";
        this.inputRoomname = undefined;
        this.preferredMediaType;
        this.totalSize = MAX_SIZE;
        this.selfRecording = {
            on: false,
            withVideo: false
        };
        this.recordOption = {
            video: false,
            floor: true,
            languages: true,
            roomname: ""
        };
        this.addedAudioStreams = new Map();
    }

    static getMimeType() {
        const possibleTypes = ["video/webm;codecs=vp8"];

        for (const type of possibleTypes) {
            if (MediaRecorder.isTypeSupported(type)) {
                return type;
            }
        }
        throw new Error("No MIME Type supported by MediaRecorder");
    }

    get mediaType() {
        if (!this.recordOption.video) {
            return "audio/webm;";
        }

        if (!this.preferredMediaType) {
            this.preferredMediaType = LocalRecordingManager.getMimeType();
        }

        return this.preferredMediaType;
    }

    initializeAudioMixer() {
        this.audioContext = new AudioContext();
        this.audioDestination = this.audioContext.createMediaStreamDestination();
        this.oscillator = this.audioContext.createOscillator();

        const gainNode = this.audioContext.createGain();
        gainNode.gain.setValueAtTime(0.001, this.audioContext.currentTime); // Set volume to 0
        this.oscillator.connect(gainNode);
        gainNode.connect(this.audioDestination);
        this.oscillator.start();
    }

    mixAudioStream(stream) {
        if (stream.getAudioTracks().length > 0 && this.audioDestination) {
            const source = this.audioContext.createMediaStreamSource(stream);

            const streamGainNode = this.audioContext.createGain();
            streamGainNode.gain.setValueAtTime(1, this.audioContext.currentTime); // Set volume to normal
            source.connect(streamGainNode);

            streamGainNode.connect(this.audioDestination);
            this.addedAudioStreams.set(stream, streamGainNode);
        }
    }

    addAudioTrackToLocalRecording(track, channel) {
        console.log("🚀 ~ LocalRecordingManager ~ addAudioTrackToLocalRecording ~ track:", track);
        if (this.selfRecording.on) {
            return;
        }
        if (track) {
            console.log(
                "🚀 ~ LocalRecordingManager ~ addAudioTrackToLocalRecording ~ channel:",
                channel
            );
            console.log(
                "🚀 ~ LocalRecordingManager ~ addAudioTrackToLocalRecording ~ track:",
                track
            );
            console.log(
                "🚀 ~ LocalRecordingManager ~ addAudioTrackToLocalRecording ~ this.recordOption.floor:",
                this.recordOption.floor
            );
            console.log(
                "🚀 ~ LocalRecordingManager ~ addAudioTrackToLocalRecording ~ this.recordOption.languages:",
                this.recordOption.languages
            );
            console.log(
                "🚀 ~ LocalRecordingManager ~ addAudioTrackToLocalRecording ~ this.inputRoomname.roomname:",
                this.inputRoomname.roomname
            );
            if (
                channel &&
                ((this.recordOption.floor &&
                    channel === CHANNEL_TYPE.FLOOR &&
                    this.inputRoomname.roomname === ORIGINAL_ROOMNAME) ||
                    (this.recordOption.languages &&
                        channel === CHANNEL_TYPE.LANGUAGES &&
                        this.inputRoomname.roomname !== ORIGINAL_ROOMNAME))
            ) {
                const stream = new MediaStream([track]);
                console.log(
                    "🚀 ~ LocalRecordingManager ~ addAudioTrackToLocalRecording ~ stream:",
                    stream
                );

                this.mixAudioStream(stream);
            }
        }
    }

    disconnectAllAudioTracks() {
        for (const [stream, gainNode] of this.addedAudioStreams.entries()) {
            console.log(
                "🚀 ~ LocalRecordingManager ~ disconnectAllAudioTracks ~ gainNode:",
                gainNode
            );
            console.log("🚀 ~ LocalRecordingManager ~ disconnectAllAudioTracks ~ stream:", stream);
            console.log(
                "🚀 ~ LocalRecordingManager ~ disconnectAllAudioTracks ~ this.audioDestination:",
                this.audioDestination
            );
            try {
                gainNode.disconnect(this.audioDestination);
            } catch (e) {
                console.log("🚀 ~ LocalRecordingManager ~ disconnectAllAudioTracks ~ e:", e);
            }
        }
        this.addedAudioStreams.clear();
    }

    removeAudioTrackFromLocalRecording(track) {
        if (!track) return;

        const stream = [...this.addedAudioStreams.keys()].find(s =>
            s.getAudioTracks().includes(track)
        );
        if (stream) {
            const gainNode = this.addedAudioStreams.get(stream);
            if (gainNode) {
                gainNode.disconnect(this.audioDestination);
                this.addedAudioStreams.delete(stream);
                track.stop();
            }
        }
    }

    getFilename() {
        const now = new Date();
        const timestamp = now.toISOString();

        console.log(
            "🚀 ~ LocalRecordingManager ~ getFilename ~ this.inputRoomname.label:",
            this.inputRoomname.label
        );
        return `${this.roomName}_${this.inputRoomname.label}_${timestamp}`;
    }

    async saveRecording(recordingData, filename) {
        const blob = await fixWebmDuration(new Blob(recordingData, { type: this.mediaType }));
        const url = URL.createObjectURL(blob);
        console.log("🚀 ~ LocalRecordingManager ~ saveRecording ~ url:", url);
        const a = document.createElement("a");
        const extension = this.mediaType.slice(
            this.mediaType.indexOf("/") + 1,
            this.mediaType.indexOf(";")
        );

        a.style.display = "none";
        a.href = url;
        a.download = `${filename}.${extension}`;
        a.click();
    }

    stopLocalRecording() {
        if (this.recorder) {
            this.recorder.stop();
            this.recorder = undefined;
            this.audioContext = undefined;
            this.audioDestination = undefined;
            this.totalSize = MAX_SIZE;
            setTimeout(() => {
                if (this.recordingData.length > 0) {
                    this.saveRecording(this.recordingData, this.getFilename());
                }
            }, 1000);
        }
    }

    async startLocalRecording(store, options) {
        const { dispatch, getState } = store;

        // @ts-ignore
        const supportsCaptureHandle =
            Boolean(navigator.mediaDevices.setCaptureHandleConfig) && !inIframe();
        const tabId = uuidV4();
        console.log("🚀 ~ LocalRecordingManager ~ startLocalRecording ~ tabId:", tabId);

        // this.selfRecording.on = true;
        this.recordOption = {
            video: options.video ?? false,
            floor: options.floor ?? true,
            languages: options.languages ?? true
        };
        this.recordingData = [];
        this.roomName = options.title ?? "";
        this.inputRoomname = options.inputRoomname;
        let gdmStream = new MediaStream();
        const tracks = getState().event.localTracks;
        const room = getState().event.room;

        if (options == "true") {
            let audioTrack = getLocalTrack(tracks, MEDIA_TYPE.AUDIO)?.track;
            let videoTrack = getLocalTrack(tracks, MEDIA_TYPE.VIDEO)?.track;

            if (!audioTrack) {
                // APP.conference.muteAudio(false);
                // setTimeout(() => APP.conference.muteAudio(true), 100);
                await new Promise(resolve => {
                    setTimeout(resolve, 100);
                });
            }
            if (videoTrack && videoTrack.readyState !== "live") {
                videoTrack = undefined;
            }
            audioTrack = getLocalTrack(getState().event.localTracks, MEDIA_TYPE.AUDIO)?.track;
            console.log(
                "🚀 ~ startLocalRecording ~ getState().event.localTracks:",
                getState().event.localTracks
            );
            if (!audioTrack && !videoTrack) {
                console.log("🚀 ~ startLocalRecording ~ videoTrack:", videoTrack);
                console.log("🚀 ~ startLocalRecording ~ audioTrack:", audioTrack);
                throw new Error("NoLocalStreams");
            }
            // this.selfRecording.withVideo = Boolean(videoTrack);
            const localTracks = [];

            audioTrack && localTracks.push(audioTrack);
            videoTrack && localTracks.push(videoTrack);
            this.stream = new MediaStream(localTracks);
        } else {
            if (supportsCaptureHandle) {
                // @ts-ignore
                navigator.mediaDevices.setCaptureHandleConfig({
                    handle: `JitsiMeet-${tabId}`,
                    permittedOrigins: ["*"]
                });
            }
            const localAudioTrack = getLocalTrack(tracks, MEDIA_TYPE.AUDIO)?.track;

            // Starting chrome 107, the recorder does not record any data if the audio stream has no tracks
            // To fix this we create a track for the local user(muted track)
            if (!localAudioTrack) {
                // APP.conference.muteAudio(false);
                // setTimeout(() => APP.conference.muteAudio(true), 100);
                await new Promise(resolve => {
                    setTimeout(resolve, 100);
                });
            }

            // handle no mic permission
            if (!getLocalTrack(getState().event.localTracks, MEDIA_TYPE.AUDIO)?.track) {
                console.log(
                    "🚀 ~ startLocalRecording ~ getState().event.localTracks:",
                    getState().event.localTracks
                );
                console.log(
                    "🚀 ~ startLocalRecording ~ getLocalTrack(getState().event.localTracks, MEDIA_TYPE.AUDIO):",
                    getLocalTrack(getState().event.localTracks, MEDIA_TYPE.AUDIO)
                );
                throw new Error("NoMicTrack");
            }

            const currentTitle = document.title;
            document.title = currentTitle;

            if (this.recordOption.video && this.inputRoomname.roomname === ORIGINAL_ROOMNAME) {
                // @ts-ignore
                gdmStream = await navigator.mediaDevices.getDisplayMedia({
                    video: { displaySurface: "browser", frameRate: 30 },
                    audio: false, // @ts-ignore
                    preferCurrentTab: true
                });
                console.log("🚀 ~ startLocalRecording ~ gdmStream:", gdmStream);

                const isBrowser =
                    gdmStream.getVideoTracks()[0].getSettings().displaySurface === "browser";

                console.log(
                    "🚀 ~ LocalRecordingManager ~ startLocalRecording ~  gdmStream.getVideoTracks()[0].getCaptureHandle():",
                    gdmStream.getVideoTracks()[0].getCaptureHandle()
                );

                // if (
                //     !isBrowser ||
                //     (supportsCaptureHandle && // @ts-ignore
                //         gdmStream.getVideoTracks()[0].getCaptureHandle()?.handle !==
                //             `JitsiMeet-${tabId}`)
                // ) {
                //     gdmStream.getTracks().forEach(track => track.stop());
                //     throw new Error("WrongSurfaceSelected");
                // }

                console.log(
                    "🚀 ~ startLocalRecording ~ gdmStream.getVideoTracks()[0]:",
                    gdmStream.getVideoTracks()[0]
                );
            }

            this.initializeAudioMixer();

            const allTracks = getState().event.audioTracks;
            console.log("🚀 ~ startLocalRecording ~ allTracks:", allTracks);

            Object.values(allTracks).forEach(track => {
                if (
                    track.audioTrack.mediaType === MEDIA_TYPE.AUDIO ||
                    track.audioTrack.type === MEDIA_TYPE.AUDIO
                ) {
                    const participantId = track.audioTrack.getParticipantId();
                    const participant =
                        room != null ? room.getParticipantById(participantId) : null;

                    if (participant != null) {
                        const audioTrack = track?.track;
                        console.log("🚀 ~ Object.values ~ track:", track);
                        console.log(
                            '🚀 ~ LocalRecordingManager ~ Object.values ~ participant.getProperty("output"):',
                            participant.getProperty("output")
                        );
                        console.log(
                            "🚀 ~ LocalRecordingManager ~ Object.values ~ this.inputRoomname.roomname:",
                            this.inputRoomname.roomname
                        );
                        console.log(
                            '🚀 ~ LocalRecordingManager ~ Object.values ~ participant.getProperty("role"):',
                            participant.getProperty("role")
                        );
                        if (
                            parseInt(participant.getProperty("role")) === UserRole.INTERPRETER &&
                            participant.getProperty("output") === this.inputRoomname.roomname &&
                            this.recordOption.languages
                        ) {
                            console.log(
                                "🚀 ~ LocalRecordingManager ~ Object.values ~ recordingManger.inputRoomname.roomname:",
                                this.inputRoomname.roomname
                            );
                            this.addAudioTrackToLocalRecording(
                                track.audioTrack.track,
                                CHANNEL_TYPE.LANGUAGES
                            );
                        } else if (
                            parseInt(participant.getProperty("role")) !== UserRole.INTERPRETER &&
                            this.recordOption.floor
                        ) {
                            console.log(
                                "🚀 ~ LocalRecordingManager ~ Object.values ~ recordingManger.inputRoomname.roomname:",
                                this.inputRoomname.roomname
                            );
                            this.addAudioTrackToLocalRecording(
                                track.audioTrack.track,
                                CHANNEL_TYPE.FLOOR
                            );
                        }
                    }
                }
            });

            // Add local audio track to the recording
            if (localAudioTrack && this.inputRoomname.roomname === ORIGINAL_ROOMNAME) {
                this.addAudioTrackToLocalRecording(localAudioTrack, CHANNEL_TYPE.FLOOR);
            }

            console.log(
                "🚀 ~ startLocalRecording ~ (this.audioDestination?.stream.getAudioTracks() || []):",
                this.audioDestination?.stream.getAudioTracks() || []
            );

            if (this.recordOption.video && this.inputRoomname.roomname === ORIGINAL_ROOMNAME) {
                this.stream = new MediaStream([
                    ...(this.audioDestination?.stream.getAudioTracks() || []),
                    gdmStream.getVideoTracks()[0]
                ]);
                console.log("🚀 ~ startLocalRecording ~ this.stream:", this.stream);
            } else {
                this.stream = new MediaStream([
                    ...(this.audioDestination?.stream.getAudioTracks() || [])
                ]);
                // this.stream = new MediaStream([
                //     ...(this.audioDestination?.stream.getAudioTracks() || [])
                // ]);
                console.log("🚀 ~ startLocalRecording ~ this.stream:", this.stream);
            }
        }

        console.log("🚀 ~ startLocalRecording ~ this.mediaType:", this.mediaType);
        this.recorder = new MediaRecorder(this.stream, {
            mimeType: this.mediaType,
            videoBitsPerSecond: VIDEO_BIT_RATE
        });
        this.recorder.addEventListener("dataavailable", e => {
            console.log("🚀 ~ startLocalRecording ~ e:", e);
            console.log(
                "🚀 ~ startLocalRecording dataavailabel~ this.inputRoomname:",
                this.inputRoomname
            );
            if (e.data && e.data.size > 0) {
                this.recordingData.push(e.data);
                this.totalSize -= e.data.size;
                if (this.totalSize <= 0) {
                    dispatch(eventStore.actions.updateRecordingStatus(false));
                }
            }
        });

        if (options !== "true") {
            this.recorder.addEventListener("stop", () => {
                console.log("🚀 ~ this.recorder.addEventListener ~ this.stream:", this.stream);
                this.stream?.getTracks().forEach(track => track.stop());

                if (this.recordOption.video) {
                    gdmStream?.getTracks().forEach(track => track.stop());
                    console.log("🚀 ~ this.recorder.addEventListener ~ gdmStream:", gdmStream);
                }
            });

            if (this.recordOption.video) {
                gdmStream?.addEventListener("inactive", () => {
                    console.log("🚀 ~ this.recorder.addEventListener ~ gdmStream:", gdmStream);
                    dispatch(eventStore.actions.updateRecordingStatus(false));
                });
            }

            this.stream.addEventListener("inactive", () => {
                if (this.recordOption.video) {
                    console.log("🚀 ~ this.recorder.addEventListener ~ gdmStream:", gdmStream);
                }

                dispatch(eventStore.actions.updateRecordingStatus(false));
            });
        }

        try {
            console.log("Starting MediaRecorder with stream:", this.stream);
            console.log("Stream tracks:", this.stream.getTracks());
            this.recorder.start(5000);
        } catch (error) {
            console.error("Error starting MediaRecorder:", error);
            if (error.name === "NotAllowedError") {
                console.error("Permissions issue: ", error.message);
            } else if (error.name === "NotFoundError") {
                console.error("No media tracks found: ", error.message);
            } else {
                console.error("Unknown error:", error.message);
            }
        }
    }

    isRecordingLocally() {
        console.log(
            "🚀 ~ LocalRecordingManager ~ isRecordingLocally ~ this.recorder:",
            this.recorder
        );
        return Boolean(this.recorder);
    }
}

export default LocalRecordingManager;
