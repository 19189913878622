import { IconButton, Paper } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
    warningWrapper: {
        background: "yellow",
        padding: theme.spacing(1)
    },
    messageFullLabel: {},
    messageLabel: {
        fontWeight: "bold"
    },
    chatEditor: {
        display: "flex",
        alignItems: "flex-end"
    },
    messageSendBtn: {
        backgroundColor: "#0b7ff7",
        borderRadius: 11,
        paddingTop: 5,
        paddingBottom: 5,
        marginLeft: 5,
        height: "100%",
        "&:hover": {
            backgroundColor: "#0473e5"
        }
    }
}));
function WarningMessage(props) {
    const [message, setMessage] = React.useState("");
    const classes = useStyles();

    function onMessageChange(e) {
        setMessage(e.target.value);
    }

    function sendWarningMessage(value) {
        if (value && value.length > 0) {
            props.sendWarningMessage(value);
            setMessage("");
        }
    }

    return (
        <Paper className={classes.warningWrapper}>
            <div className={classes.messageFullLabel}>
                Warning to <span className={classes.messageLabel}>{props.selectedUser}</span>
            </div>
            <div className={classes.chatEditor}>
                {/* <textarea placeholder="Type here..." style="height: 62px; margin-top: 0px; margin-bottom: 0px;"></textarea> */}
                <textarea
                    id="warningInput"
                    value={message}
                    className={classes.textInput}
                    onChange={onMessageChange}
                ></textarea>
                <IconButton
                    className={classes.messageSendBtn}
                    onClick={() => sendWarningMessage(message)}
                >
                    <SendIcon />
                </IconButton>
            </div>
        </Paper>
    );
}

export default WarningMessage;
